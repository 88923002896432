import type { FunctionComponent } from 'react';

import { Fragment } from 'react';
import { CiEdit } from 'react-icons/ci';

import { addSpaceToTitle } from '@/utils/constant';


// type TDetailsItem = (props: any) => NonNullable<ReactNode> | null;
type TDetailsItem = FunctionComponent<{
	displayDeciders: {
		nestedInformation: string[],
		omitFromDisplay: string[],
	},
	cardDataState: any,
	handleOpenEditModal: (
		title: string,
		dataToEdit: any,
		parentKey?: string
	) => void,
	itemInfo: {
		title: string,
		edit: boolean,
	},
	nested: boolean,
}>;

const DetailsItem: TDetailsItem = ({cardDataState, displayDeciders, handleOpenEditModal, itemInfo, nested}) => {
	const readonlyProps = ['cacnatureofbusinessid', 'cacspecificnatureofbusinessid'];

	if (!cardDataState) return null;
	if (!itemInfo) return null;

	if (nested) return <>
		{Object.entries(cardDataState).map(([outerObject, outerValue]) => {
			if (displayDeciders['omitFromDisplay'].includes(outerObject)) return null;

			return (
				<Fragment key={outerObject}>
					{/* Render outer object. */}
					{`${outerValue}` === '[object Object]' ? null : (
						<div>
							<div className='text-sm opacity-75 flex gap-2 items-center'>
								{addSpaceToTitle(outerObject)}

								{!readonlyProps.includes(outerObject.toLowerCase()) && itemInfo?.['edit'] ? (
									<button onClick={() => handleOpenEditModal(itemInfo?.['title'], {[outerObject]: outerValue})}
											className='cursor-pointer'>
										<span className='sr-only'>
											Edit
										</span>
										<CiEdit size={15}/>
									</button>
								) : null}
							</div>
							<div>
								{(outerValue === '' || outerValue === null) && !readonlyProps.includes(outerObject.toLowerCase()) ? (
									<p className='break-all text-amber font-semibold'>
										Value is null, enter appropriate info
									</p>
								) : (
									<p className='break-all'>
										{`${outerValue}`}
									</p>
								)}
							</div>
						</div>
					)}

					{/* Render inner object. */}
					{(() => {
						if (!outerValue) return null;
						if (!displayDeciders['nestedInformation'].includes(outerObject)) return null;
			
						return <>
							{Object.entries(outerValue).map(([innerObject, InnerValue]) => (
								<div key={innerObject}>
									<div className='text-sm opacity-75 flex gap-2 items-center'>
										{outerObject} {' '}

										{addSpaceToTitle(innerObject).toLowerCase() === 'district' ? (
											'LGA'
										) : (
											addSpaceToTitle(innerObject)
										)}

										{!readonlyProps.includes(innerObject.toLowerCase()) ? (
											<button className='cursor-pointer text-sm opacity-75 flex gap-2 items-center'
													onClick={() => {
														handleOpenEditModal(itemInfo?.['title'], {[innerObject]: InnerValue}, outerObject);
													}}>
												{itemInfo?.['edit'] ? <CiEdit size={15}/> : null}
											</button>
										) : null}
									</div>

									<div>
										{(InnerValue === '' || InnerValue === null) && !readonlyProps.includes(innerObject.toLowerCase()) ? (
											<p className='break-all text-amber font-semibold'>
												Value is null, enter appropriate info
											</p>
										) : (
											<p className='break-all'>
												{`${InnerValue}`}
											</p>
										)}
									</div>
								</div>
							))}
						</>
					})()}
				</Fragment>
			);
		})}
	</>;

	else return <>
		{Object.entries(cardDataState).map(([key, value]) => {
			if (displayDeciders['omitFromDisplay'].includes(key)) return null;

			else return (
				<div key={key}>
					<button className='cursor-pointer text-sm opacity-75 flex gap-2 items-center'
							onClick={() => {
								handleOpenEditModal(itemInfo?.['title'], {[key]: value})
							}}>
						{addSpaceToTitle(key)}

						{itemInfo?.['edit'] ? (
							<CiEdit size={15}/>
						) : null}
					</button>

					<p className='break-all'>
						{value!?.toString()}
					</p>
				</div>
			);
		})}
	</>;
}

export default DetailsItem;
