import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { createOfflineApplication, fetchApplicationDetails, fetchApplicationPreviewDetails, fetchExtraSuggestedNames, fetchProgressTracking, sendApplicationToQueue, updateUserApplication } from "../api/Applications"
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { SendToQueuePayload } from "../../utils/typings/Api";
import { OfflineApplicationDetails } from "../../utils/typings/Applications";



export const useApplicationDetailsData = () => {
    let { id } = useParams();
    const location = useLocation()
    const [searchParams] = useSearchParams();

    const companyId = location?.state?.companyId ?? searchParams.get('companyId')
    const applicationId = id! ?? searchParams.get('applicationId')

    const { isError, isLoading, data, isFetching } = useQuery({
        queryKey: ['application-preview-details', id, applicationId],
        queryFn: () => fetchApplicationPreviewDetails({ applicationId: applicationId, companyId:companyId }),
        //cacheTime: 0,
        staleTime: 0,
        refetchOnWindowFocus: true,
        enabled: !!applicationId && !!companyId
    })

    return { isError, isLoading, data, isFetching }
}

export const useApplicationInfoData = ({ enable }: { enable: boolean | string}) => {

    let { id } = useParams();
    const location = useLocation()
    const [searchParams] = useSearchParams();

    const customerEmail = location?.state?.customerEmail ?? searchParams.get('customerEmail')
    const applicationId = id ?? searchParams.get('applicationId')
    
    const enabled = enable === 'default' ? !!applicationId && !!customerEmail : !!enable

    const { isError, isLoading, data } = useQuery({
        queryKey: ['application-details', id, applicationId],
        queryFn: () => fetchApplicationDetails({ applicationId: applicationId!, customerEmail: customerEmail!}),
        // cacheTime: 0,
        enabled: enabled
    })

    return { isError, isLoading, data }
}

export const useSendApplicationToQueueMutation = () => {

    const applicationToQueueMutation = useMutation({
        mutationFn: (data:SendToQueuePayload) => sendApplicationToQueue(data),
        onError: (error) => {
            toast.error(error as string, {
                toastId: "send-application-to-queue"
            });
        },
        onSuccess: () => {
            toast.success("Application successfully sent to queue", {
                toastId: "sent-to-application-success"
            });
        },
    })

    return applicationToQueueMutation
}

export const useUpdateApplicationDetailsMutation = ({ applicationId, companyId, setEditSuccessful }:{ applicationId?: string, companyId?: string, setEditSuccessful?: (val: boolean) => void }) => {
    const { id } = useParams()
    const queryClient = useQueryClient()
    const location = useLocation()
    const [searchParams] = useSearchParams()

    const appId = id! ?? applicationId ?? location?.state?.applicationId ?? searchParams.get('applicationId')
    const companyIdMerged = location?.state?.companyId ?? searchParams.get('companyId') ?? companyId
    const updateApplicationDetailsMutation = useMutation({
        mutationFn: (data) => updateUserApplication(appId, data, companyIdMerged),
        onError: (error:string) => {
            localStorage.removeItem('editDataForLogs')
            toast.error(`${error}`, {
                toastId: "application-details-removed"
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['application-preview-details'] })
            localStorage.removeItem('editDataForLogs')
            localStorage.removeItem('state')
            setEditSuccessful && setEditSuccessful(true)
            toast.success("Application details updated successfully", {
                toastId: "application-details-update"
            })
        },
    })

    return updateApplicationDetailsMutation
}

export const useGetProgressTrackingQuery = () => {

    const { id } = useParams()

    const { isError, isLoading, data } = useQuery({
        queryKey: ['process-tracking', id],
        queryFn: () => fetchProgressTracking(id!),
        enabled: !!id
    })

    return { isError, isLoading, data }
}


export const useCreateOfflineApplicationMutation = (callback: () => void) => {

    const queryClient = useQueryClient()

    const createOfflineApplicationMutation = useMutation({
        mutationFn: (data:OfflineApplicationDetails) => createOfflineApplication(data),
        onError: (error: { response: { data: { message: string }}}) => {
            toast.error(JSON.parse(error.response.data.message).error, {
                toastId: "offline-application-failed"
            });
        },
        onSuccess: () => {
            callback()
            queryClient.invalidateQueries({ queryKey: ['offline-applications'] })
            toast.success("Offline Application TIN Generation ", {
                toastId: "offline-application-update"
            })
        },
    })

    return createOfflineApplicationMutation
}

export const useFetchExtraSuggestedNames = () => {

    const [searchParams] = useSearchParams()

    const applicationId = searchParams.get('applicationId')

    const { isError, isLoading, data } = useQuery({
        queryKey: ['extra-suggested-names', applicationId],
        queryFn: () => fetchExtraSuggestedNames(applicationId!),
        enabled: !!applicationId
    })

    return { isError, isLoading, data }
}