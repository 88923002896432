import React, {  useEffect, useState } from 'react'
import Button from '../../ui/Button'
import Loader from '../../ui/Loader'
import DetailsItem from './DetailsItem'
import { toast } from 'react-toastify'
import { useSearchParams } from 'react-router-dom'
import { businessInfoToUpdate, companyInfoToUpdate } from '../../../../utils/helperFunctions/businessInfoToUpdate'
import { useUpdateApplicationDetailsMutation } from '../../../hooks/useApplicationDetailsQuery'

const EditApplicationDetailsModal = React.lazy(() => import('../../modals/applications/EditApplicationDetailsModal'))

const omitFromDisplay = [
    'identificationDocumentUrl', 'IdentificationDocumentUrl',
    // 'companyAddress', 'postalAddress', 'residentialAddress',
    'passportPhotographUrl', 'signatureUrl',
    'SignatureUrl', 'IdentificationDocumentUrl',
    'PassportPhotographUrl',
    'fileNameUrlPairs',
    // 'BusinessAddress', 'businessAddress', 'financialRecordKeeperAddress',
    'trademarkClassifications', 'representationUrl',
    'passportDataPageUrl', 'certificateUrl',
    'companyRegistrationCertificateUrl',
    'birthCertificateUrl', 'nonCriminalRecordDocumentUrl',
    'proprietorInformation', 'BankAccountOpeningDetails',
    'ProprietorInformation',
    // 'PostalAddress', 'ResidentialAddress',
    'CompanyProfile',
    'DirectorInformation', 'SignatoryInformation',
    'UtilityBillUrl', 'ReferrFormUrl',
    'proprietorIDUrl', 'businessInformation',
    'countryTrademarkClassifications',
    'proficiencyCertificateUrl',
]


const nestedInformation = [
    'postalAddress', 'residentialAddress',
    'PostalAddress', 'ResidentialAddress',
    'BusinessAddress', 'companyAddress',
    'businessAddress', 'financialRecordKeeperAddress',
]

function DetailsCard({ title, cardData, applicationDetails, nested, edit }: any) {
    const [cardDataState, setCardDataState] = useState(cardData);
    const [openEditModal, setOpenEditModal] = useState<boolean>(false);
    const [sectionData, setSectionData] = useState<any[]>([]);
    const [searchParams] = useSearchParams();


    useEffect(() => {
        setCardDataState(cardData);
    }, [cardData]);
    

    const handleOpenEditModal = (section: string, data: any, key?: string) => {
        if (!edit) return;

        setSectionData([ section, data, key ]);
        setOpenEditModal(!openEditModal);
    }

    const updateApplicationDetailsMutation = useUpdateApplicationDetailsMutation({})

    const handleUpdateApplicationDetails = () => {
        let data = applicationDetails
        if(title === 'Company Information'){
            data = companyInfoToUpdate({ data: data, updatedData: cardDataState})
        }
        
        if(title === 'Business Information'){
            data = businessInfoToUpdate(applicationDetails, cardDataState, data)
            if(!data.cacNatureOfBusiness || !data.cacNatureOfBusinessId || !data.cacSpecificNatureOfBusiness || !data.cacSpecificNatureOfBusinessId){
                toast.warning('Please enter the missing nature of business details')
                return
            }
        }
        if(title === 'Proprietor Information'){
            if(data.proprietorInformation){
                data = {
                    ...data,
                    proprietorInformation: {
                        ...data.proprietorInformation,
                        soleProprietor: cardDataState
                    }
                }
            }
            if(data.ProprietorInformation){
                data = {
                    ...data,
                    ProprietorInformation: {
                        ...data.ProprietorInformation,
                        SoleProprietor: cardDataState
                    }
                }
            }
        }

        if(!searchParams.get('companyId')){
            toast.warning("Company Id is not available", {
                toastId: "company Id unavailable"
            });
            return
        }
   
        updateApplicationDetailsMutation.mutate(data)
    }

    const displayDeciders = {
        omitFromDisplay,
        nestedInformation
    }

    const itemInfo = {
        title, 
        edit,
    }

    return <>
        <EditApplicationDetailsModal
            setcardData={(data: any) => setCardDataState(data)}
            onClose={() => setOpenEditModal(false)}
            applicationDetails={applicationDetails}
            sectionData={sectionData}
            cardData={cardDataState}
            open={openEditModal}
        />

        <div className={`${edit ? 'border rounded-md px-5 md:px-10 pb-10 my-5' : 'border-t '} border-lightGray`}>
            {edit && !applicationDetails?.cacNatureOfBusiness || !applicationDetails?.cacSpecificNatureOfBusiness || !applicationDetails?.cacNatureOfBusinessId || !applicationDetails?.cacSpecificNatureOfBusinessId &&
                <div className='rounded-md mt-5 bg-lightRed text-center py-4'>
                    <p className='text-red'>
                        One or more Nature of Business data is missing,
                        application update will not be possible.
                    </p>
                </div>
            }

            <div className={`flex flex-col xl:flex-row lg:w-full justify-between gap-10 xl:gap-0   py-10`}>
                <div className='lg:w-2/5'>
                    <h3 className='font-semibold'>
                        {title}
                    </h3>
                    <p className='opacity-75'>
                        Use this to file the application
                    </p>
                </div>
                <div className=' lg:w-3/5'>
                    <div className='grid md:grid-cols-2 gap-5'>
                        <DetailsItem displayDeciders={displayDeciders} nested={nested} handleOpenEditModal={handleOpenEditModal} itemInfo={itemInfo} cardDataState={cardDataState}/>
                    </div>
                </div>
            </div>

            {edit ? (
                <div className='flex justify-end'>
                    <Button onClick={handleUpdateApplicationDetails}>
                        {updateApplicationDetailsMutation.isLoading ? (
                            <Loader color='stroke-white'/>
                        ) : `Update ${ title }`}
                    </Button>
                </div>
            ) : null}
        </div>
    </>;
}

export default DetailsCard