import type { SetStateAction } from "react";
import { useMutation, useQuery } from "@tanstack/react-query"
import { downloadApplicationDocument, fetchApplicationDocuments } from "../api/Applications"
import { useParams } from "react-router-dom";
import { byteToUint8Array, contentTypeStrings } from "../../utils/constant";
import { toast } from "react-toastify";
import { downloadFile } from "../../utils/helperFunctions/downloadFile";
import { DownloadDocumentInterface } from "@/utils/typings/Applications";



export const useApplicationDocumentsData = () => {

    let { id } = useParams();

    const { isError, isLoading, data } = useQuery({
        queryKey: ['application-documents', id],
        queryFn: () => fetchApplicationDocuments({ applicationId:id! }),
        staleTime: 0,
        cacheTime: 0,
        enabled: !!id
    })

    return { isError, isLoading, data }
}


export type TUseDownloadDocumentProps = { 
    queryKey: string,
    /**
     * @deprecated
     * This is likely redundant and confusing, since queryKey can still
     * combine multiple values through JSON.strigify or a template literal.
     * Ultimately, {@link useDownloadDocuments} should probably be refactored
     * to allow passing an array to `queryKey` and forwarding that directy to useQuery.
     */
    refetchTrigger?: any, 
    setPdf: (base64EncodedString: React.SetStateAction<string | undefined>) => void,
    setImg: (base64EncodedString: React.SetStateAction<string | undefined>) => void,
    /** @deprecated Use {@link setFileName} instead, as it is a more descriptive name. */
    setFile?: (fileName: SetStateAction<string | undefined>) => void,
    setFileName?: (fileName: SetStateAction<string | undefined>) => void,
    setFileContainer?: (
        fileContainer: SetStateAction<DownloadDocumentInterface | undefined>
    ) => void,
    enabled: boolean,
    queryData: {
        url: string,
    },
}

type TUseDownloadDocuments = (
    props: TUseDownloadDocumentProps
) => { isError: boolean, isFetching: boolean, data: any };

export const useDownloadDocuments: TUseDownloadDocuments = (props) => {
    const { queryKey, refetchTrigger, setImg, setPdf, enabled, queryData } = props;

    const { isError, isFetching, data } = useQuery({
        queryKey: [queryKey, refetchTrigger],
        queryFn: () => downloadApplicationDocument(queryData),
        enabled: enabled,
        staleTime: 0,
        onSuccess: (fileContainer: DownloadDocumentInterface) => {
            props.setFileName?.(fileContainer?.fileName);
            props.setFileContainer?.(fileContainer);

            if (props.setFile) {
                console.warn('The setFile prop is deprecated. Use setFileName instead. \n\n%s', (new Error()).stack);
                props.setFile(fileContainer?.fileName);
            }

            let fileType = '';
            const type = fileContainer?.contentType?.split('/')[1];
            const arrayBuffer = byteToUint8Array(fileContainer?.file!);

            if (type === 'octet-stream') {
                const fileExtension = fileContainer.fileName.substring(fileContainer.fileName.length - 3);
                const extIsKnownType = contentTypeStrings.includes(fileExtension);
                if (extIsKnownType) fileType = fileExtension;
                else fileType = 'jpeg';
            }

            if (fileType === 'pdf' || type === 'pdf') {
                setImg('');
                setPdf(fileContainer.file);
            } else {
                const blob = new Blob([arrayBuffer], { type: type ?? fileType });
                setPdf('');
                setImg(URL.createObjectURL(blob));
            }
        }
    })

    return { isError, isFetching, data };
}


export const useDownloadMutation = () => {
    const downloadMutation = useMutation({
        mutationFn: (data:{ url: string }) => downloadApplicationDocument(data),
        onError: () => {
            toast.error("Download failed", {
                toastId: "download-failed"
            });
        },
        onSuccess: (data) => {
            downloadFile(data)
    
            toast.success("Download successful", {
                toastId: "download-successful"
            });
        },
    })

    return downloadMutation
}