import { useQuery } from "@tanstack/react-query"
import { fetchServiceTimelines, fetchSpecificService } from "../api/Applications"
import { useLocation, useParams, useSearchParams } from "react-router-dom";



export const useServiceTimelineData = ({ enable }: { enable: boolean | string}) => {

    let { id } = useParams();
    const location = useLocation()
    const [searchParams] = useSearchParams();

    const enabled = enable === 'default' ? (!!id && (!!location?.state?.customerEmail! || !!searchParams.get('customerEmail')))! : !!enable

    
    const { isError, isLoading, data } = useQuery({
        queryKey: ['service-timelines', id, location?.state?.customerEmail],
        queryFn: () => fetchServiceTimelines({ applicationId:id!, customerEmail: searchParams.get('customerEmail') ?? location?.state?.customerEmail! }),
        enabled
    })

    return { isError, isLoading, data }
}

export const useSpecificServiceData = ({ timelineId }: { timelineId: string}) => {

    let { id } = useParams();
    const location = useLocation()
    const [searchParams] = useSearchParams();

    const { isLoading, isError, data } = useQuery({
        queryKey: ['specific-services', timelineId],
        queryFn: () => fetchSpecificService(
            { 
                applicationId: id!, 
                customerEmail: location?.state?.customerEmail ?? searchParams.get('customerEmail'),
                timelineId: timelineId!,
            }
        ),
        enabled: !!timelineId && (!!location?.state?.customerEmail || !!searchParams.get('customerEmail')),
        staleTime: 0,
    })

    return { isError, isLoading, data }
}