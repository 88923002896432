import React from "react"


interface InputInterface {
    type: string,
    placeholder?: string,
    icon?: JSX.Element,
    className?: string,
    error?: string,
    [key:string]: any
}
const Input = React.forwardRef(({ errors, id, type, placeholder, nested, icon, label, className, ...otherProps }: Omit<InputInterface, 'ref'>, ref) => {
    const errorExists = errors?.[otherProps.name] ?? errors?.message
    return (
        <div className={`${type === 'checkbox' && 'flex gap-3 justify-end items-center flex-row-reverse'}`}>
            {
                label && <label htmlFor={id} className='text-sm'> { label }</label>
            }
            <div className='relative'>
                <input id={id} checked={otherProps.checked} onChange={otherProps.onChange} value={otherProps.value} name={otherProps.name} type={type} placeholder={placeholder} className={`${className} ${errorExists ? 'border-red ' : 'border-lightGray'}` + ' mt-2 border rounded-md py-2 text-sm outline-teal text-gray'} {...otherProps} />
                {icon && <span className='absolute top-5 left-3'>{icon}</span>}
            </div>
            { errors && <p className='text-red text-sm'>{ errors[otherProps.name]?.message ?? errors?.message }</p>}
        </div>
    )
})

export default Input